import { authContext } from "auth/auth.provider";
import { Suspense, useContext } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import Loader from "views/widgets/loader";

const PublicRoute = ({
  component: Component,
  path,
  name,
  exact,
  isProtected,
  props,
  ...rest
}: IRoute) => {
  const { isAuthenticated } = useContext(authContext);
  return (
    <Route
      {...props}
      path={path}
      exact={exact}
      render={(props: RouteComponentProps<any>) =>
        isAuthenticated && name === "Login" ? (
          
          <Redirect to="/" />
        ) : (
          <Suspense fallback={<Loader />}>
            <Component name={name} {...props} {...rest} />
          </Suspense>
        )
      }
    />
  );
};

export default PublicRoute;
