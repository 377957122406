import { constants } from "script/constant";

function SidebarHelper({
  user,
  plugins,
  navigation,
  isAIEnabled,
  userSettings,
  isCreateMatterEnabled,
}) {
  // const ManageGraphEvent = () => {
  //   let newVal;
  //   if (!user.isDocketingUser) {
  //     newVal = navigation
  //       ?.sort((a, b) => a.id - b.id)
  //       .filter(nav => nav.id !== constants.SIDEBAR_IDS.ManageEvents);
  //   } else {
  //     newVal = navigation;
  //   }
  //   return newVal;
  // };

  const manageAISettings = () => {
    let newVal;
    if (!isAIEnabled) {
      newVal = navigation
        ?.sort((a, b) => a.id - b.id)
        .filter(nav => nav.id !== constants.SIDEBAR_IDS.AISettingID);
    } else {
      newVal = navigation;
    }
    return newVal;
  };

  const loadPlugin = navigationData => {
    let newVal;
    let pluginID = `${process.env.REACT_APP_PLUGIN_ID}`;
    const netDocumentsDatas = plugins?.filter(a => a.pluginID == pluginID);
    if (netDocumentsDatas !== null) {
      if (netDocumentsDatas[0]?.isActive === false) {
        newVal = navigationData
          ?.sort((a, b) => a.id - b.id)
          .filter(nav => nav.id !== constants.SIDEBAR_IDS.NetDocument);
      } else {
        newVal = navigationData;
      }
      return newVal;
    }
  };
  const loadIManage = navigationData => {
    let newVal;
    let pluginID = `${process.env.REACT_APP_IMANAGE_PLUGIN_ID}`;
    const imanageDatas = plugins?.filter(a => a.pluginID == pluginID);
    if (imanageDatas !== null) {
      if (imanageDatas[0]?.isActive === false) {
        newVal = navigationData
          ?.sort((a, b) => a.id - b.id)
          .filter(nav => nav.id !== constants.SIDEBAR_IDS.IManage);
      } else {
        newVal = navigationData;
      }
      return newVal;
    }
  };

  const removingItemsFromMatterData = navigationData => {
    if (userSettings.isPlannerEnabled === false) {
      let newVal = navigationData
        ?.sort((a, b) => a.id - b.id)
        .filter(nav => nav.id === constants.SIDEBAR_IDS.MatterID);
      newVal[0]._children = newVal[0]._children?.filter(item => item.to !== "/matter/task");
    }
    if (isCreateMatterEnabled === false) {
      let newVal = navigationData
        ?.sort((a, b) => a.id - b.id)
        .filter(nav => nav.id === constants.SIDEBAR_IDS.MatterID);
      newVal[0]._children = newVal[0]._children?.filter(item => item.to !== "/matter/create-matter");
    }
    if (userSettings.canCreateMatter === false) {
      let newVal = navigationData
        ?.sort((a, b) => a.id - b.id)
        .filter(nav => nav.id === constants.SIDEBAR_IDS.MatterID);
      newVal[0]._children = newVal[0]._children?.filter(item => item.to !== "/matter/create-matter");
    }
    
    if (!user.isDocketingUser) {
      let newVal = navigationData
        ?.sort((a, b) => a.id - b.id)
        .filter(nav => nav.id === constants.SIDEBAR_IDS.ManageUser);
      newVal[0]._children = newVal[0]._children?.filter(item => item.to !== "/users/audit-user");
    }
    if (!user.isDocketingUser) {
      let newVal = navigationData
        ?.sort((a, b) => a.id - b.id)
        .filter(nav => nav.id === constants.SIDEBAR_IDS.ManageEvents);
      newVal[0]._children = newVal[0]._children?.filter(item => item.to !== "/event/find-events");
    }
    if (!user.isDocketingUser) {
      let newVal = navigationData
        ?.sort((a, b) => a.id - b.id)
        .filter(nav => nav.id === constants.SIDEBAR_IDS.ManageEvents);
      newVal[0]._children = newVal[0]._children?.filter(item => item.to !== "/event/duplicate-events");
    }
  };
  const finalCode = () => {
    let value;
    // value = ManageGraphEvent();
    value = manageAISettings();
    value = loadPlugin(value);
    value = loadIManage(value);
    removingItemsFromMatterData(value);
    return value;
  };

  return finalCode();
}
export default SidebarHelper;
