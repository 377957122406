const _trTranslation = {
  lang_code: "TR",
  hello: "Merhaba",
  layout: {
    sidebar: {
      nav_items: {
        dashboard: "Gösterge Paneli",
        firm: "Firma",
        profile: "Firma Profil",
        settings: "Firma Ayarlar",
        matters: "Önemli olmak",
        create_new_matter: "Yeni Konu Yarat",
        manage_matters: "Konuyu Yönet",
        customize_deadlines: "Son Tarihleri ​​Özelleştir",
        manage_group: "Grubu Yönet",
        copy_matter_template: "Konu Şablonunu Kopyala",
        naming_policy: "Adlandırma Politikaları",
        manage_teams_templates: "Konuyu Takım Şablonları",
        contact: "İletişim",
        manage_contacts: "Kişileri yönet",
        contact_tags: "yönet Etiketler",
        meeting: "Toplantı",
        config_meetings: "Toplantıları Yapılandır",
        reports: "Bildiri",
        data_range_report: "Tarih Aralığı Raporu",
        case_sharing_report: "Vaka Paylaşım Raporu",
        rule_change_report: "Kural Değişikliği Raporu",
        modified_deadline_report: "Değiştirilmiş Son Tarih Raporu",
        download_report: "Tüm Zamanların Raporunu İndirin",
        users: "Kullanıcılar",
        timesheet: "Zaman planı",
        activity: "Aktivite",
        push_notifications: "Push bildirimleri",
        user_activity_report: "Kullanıcı Etkinliği Raporu",
        plugins: "Üstesinden gelmek Eklentiler",
        help: "Yardım",
        generate_url: "URL oluştur",
        practice_area: "uygulama alanı",
        net_documents: "Net Documents",
        schedule_teams_report: "Takım Raporlarını Planla",
        clause_bank: "Madde Bankalar",
        archive_report: "Dava Arşiv raporu",
        deadline_audit: "Son Denetim",
        microsoft_teams: "Microsoft Teams",
        manage_users: "Kullanıcıları Yönet",
        netdocuments: "NetDocuments",
        customized_link: "Özel Bağlantı",
        view_matters: "Konuları Görüntüle",
        matter_archive_report: "Konu Arşiv Raporu",
        matter_tag: "Madde Etiketleri",
        find_events: "Etkinlikleri Bul",
        duplicate_events: "Yinelenen Etkinlikler",
        cancelled_events: "İptal Edilen Etkinlikler",
        manage_graph_event: "Etkinliği Yönet",
        task: "Görev",
        audit_user_calander: "Kullanıcı Takvimini Denetle",
        help_and_notification: "Help & Notifications",
        app_notification: "App Notification",
        support: "Get Help",
        whats_new: "What's New",
        onboarding: "Onboarding",
        ltb_support: "Support Manual",
        rule_update: "Rule Update",
        release_notes: "Release Notes",
        training: "Training",
        powershell: "PowerShell",
        open_ai_setting: "Setting",
        prompt: "çabuk",
        ai: "AI",
        document_manager: "Document Manager",
        matter_report: "Matter Report",
        imanage: "iManage",
        imanage_matter: "iManage Matters",
        audit_user: "Kullanıcıyı Denetleme",
        audit_firm_deadlines: "Denetim Firması Son Teslim Tarihleri",
        user_tags: "User Tags",
        ai_tag: "AI Tags",
        ltb_diagnostic: "teşhis"
      },
    },
  },
  page: {
    help: {
      heading:
        "LawToolBox ile olan deneyiminizin mümkün olduğunca kullanıcı dostu ve yardımcı olmasını istiyoruz.",
      make_private_case: {
        title: "Avukat Davalarını Özelleştirin.",
        message:
          'Ayarlar\'a gidin > kullanıcıyı bulun ve "IsPrivate" kutusunu işaretleyin. Firmadaki herhangi bir kullanıcı "özel" olarak işaretlenirse, hiçbir kullanıcının bu kullanıcıların konularına veya vakalarına erişimi yoktur (bu konular o kullanıcıyla açıkça paylaşılmadığı sürece) ve diğer kullanıcılar o kullanıcıya ait raporları veya firma geneli raporları görüntüleyemez. Herhangi bir kullanıcı "özel" olarak işaretlenirse, tüm firma genelinde konulara sınırsız erişimi olan tek kullanıcılar "CanManageAdminPortal" olarak tanımlanan kullanıcılardır.',
      },
      control: {
        title: "Yönetici Portalına Kimlerin Erişebileceğini Kontrol Edin",
        message:
          'LawToolBox Firma Yönetici Portalına erişebilen YALNIZCA kullanıcılar, Microsoft kiracılığında "genel yöneticiler" olarak belirlenmiş kullanıcılardır. LawToolBox Admin Portal\'a erişimi daha fazla sınırlamak istiyorsanız, Ayarlar > kullanıcıları bulun > seçeneğine giderek “CanManageAdminPortal” onay kutusunu seçebilirsiniz.',
      },
      disable_access: {
        title:
          "LawToolBox Uygulamasına Kullanıcı Erişimini Devre Dışı Bırakın.",
        message:
          "> Kullanıcılar'a gidin, ardından LawToolBox uygulamasına erişimlerini kontrol etmek istediğiniz kullanıcıyı bulun. Bir kullanıcıyı devre dışı bırakırsanız, kullanıcı uygulama paylaşım listesinde görünmeye devam eder ve Microsoft Grupları aracılığıyla Outlook ile eşitleme etkinse, paylaşılan tüm son tarihler takvimlerinde görünür. Bir kullanıcı etkinleştirilirse, tam erişime sahiptir.",
      },
      add_edit_user: {
        title: "Firmadan Kullanıcı Ekleme, Düzenleme veya Kaldırma.",
        message:
          'Kullanıcılar\'a gidin, ardından "Ekle, "Düzenle" ve "Sil" için inanılmaz derecede sezgisel düğmeleri izleyin. Bir kullanıcıyı sildikten sonra geri alınamazlar. Kullanıcıların e-posta kimlik bilgilerini girerken HATA YAPTIysanız, kullanıcıyı silin ve yeniden ekleyin. Office 365 e-posta adresinden farklı bir UPN e-posta adresi girmeniz gerekiyorsa, support@lawtoolbox.com ile iletişime geçin.',
        options: [
          "SSS",
          "Eğitim malzemeleri",
          "Video Kitaplığı",
          "Özellikler",
          "Demo Planla",
          "LawToolBox365 Broşürü",
          "Office Blog Gönderiyle Daha İyi",
          "Satış Konuşması Destesini İndirin",
          "Kural Bazlı Son Tarihlere İhtiyaç Duyan Avukatlar",
          "Microsoft'un WW ISV Vitrin Videosunu ziyaret edin",
          "Bir İnceleme Oku",
          "Meslektaşınıza Örnek E-posta Gönderin",
        ],
      },
      deleting_user: {
        title: "Kullanıcıları Silme.",
        steps: [
          'ADIM 1: Yönetici portalından yöneticiler, ayrılan kullanıcı için "Kullanıcılar"ı ve "kullanıcıyı sil"i seçebilir. Bu, lisansı diğer kullanıcılar için serbest bırakacaktır.',
          "ADIM 2: Yöneticiye, LawToolBox tarafından oluşturulan tüm gruplardan (yanı sıra LawToolBox ile ilgisi olmayan gruplardan) kullanıcıları kaldırmak için Office 365 yönetici portalına gitmesi hatırlatılacaktır. 2. adımı, yöneticinin adımı attığı gün gerçekleştirmenizi öneririz. 1. Bir kullanıcının silinmesi bir lisansı serbest bırakacaktır.",
        ],
      },
      configure_firm_level: {
        title:
          "Firma Düzeyinde Görünen LawToolBox Fonksiyonlarını Yapılandırın.",
        message:
          "Aşağıdaki özellikleri açmak ve kapatmak için Firma > Ayar'a tıklayın: konu oluştur, takvimi görüntüle, paylaşılan Gelen Kutusunu görüntüle, OneNote'u görüntüle, belge klasörlerini görüntüle, takvim girişlerini kuruluş dışındaki kişilerle paylaş ve e-postaları gruba kaydet.",
      },
      configure_user_level: {
        title:
          "Kullanıcı Düzeyinde Görünen LawToolBox İşlevlerini Yapılandırın.",
        message:
          "Aşağıdaki özellikleri açmak ve kapatmak için Kullanıcılar > üzerine tıklayın > ardından kullanıcının adına tıklayın > Ayar'a tıklayın: konu oluştur, son tarihleri ​​düzenle, son tarihleri ​​sil, son tarihleri ​​hesapla, konuları paylaş, E-postayı Kaydet, Konuları kapat, firma konularını görüntüle, e-posta hatırlatıcıları al, ve dahası. grup.",
      },
      more_message:
        "adresinde her zaman gezinebilir ve bilgileri keşfedebilirsiniz.",
      contact_us: "Bize ulaşın: support@lawtoolbox.com, 303-759-3572",
    },
  },
};

export default _trTranslation;
