const apiUrls = {
  USER_ME_URI: "/api/user/info",
  MANAGE_MATTER: "/Matter/Index",
  MATTER_ASSOCIATED_USERS: "/Matter/GetMatterAssociatedUsers",

  //Tags
  GET_TAGS: "/api/tags",
  GET_ALL_USERS: "/api/user/all",
  // POST_MATTER_TAGS = " /api/matter/{matterId}/tags/create",

  //Matters
  GET_USER_MATTER_URI: "/Matter/List",
  GET_MATTER_SHARED_USER_URI: "/Matter/Access",
  GET_MATTER_TRIGGER_DATES: "/Deadline/TriggerDates",
  GET_HEARING_NOTES: "/HearingNote/gethearingNotes",
  CALCULATE_DEADLINE: "/Deadline/Create",
  GET_STATE_TOOLSETS: "/Matter/Toolsets",
  UPDATE_MATTER_TOOLSET: "/Deadline/UpdateRuleSet",
  GET_MATTER_BY_ID: "/api/Matter/Dashboard",
  GET_MATTER_CALENDAR: "/deadline/matterDeadline",
  GET_PAGINATED_USER: "api/user/getAllPaginatedUser",

  //Team
  CREATE_TEAM: "/api/team/",
  GET_TEAM_TEMPLATES: "/api/teamtemplate",

  //Matter Group
  GET_GROUP_STATUS: "/api/group/status/",
  CREATE_GROUP: "/api/group/create",
  ATTACH_GROUP: "/api/group/attach",
  SEARCH_GROUP: "api/group/search?groupName=",
  GET_MATTER_ACCESS: "Matter/Access/",


  //PIN Add
  PIN_ADD: "/api/pin/add",
  PIN_DELETE: "/api/pin/delete",
  //SignUp
  CREATE_NEW_FIRM_SUBSCRIPTION_DETAIL: "/api/firm/create",
  GET_USER_DETAILS: "Account/getSignUpUserDetails",
  GET_STATES: "/Account/GetAllStates",
  GET_STATE_TIMEZONE: "/Account/GetStateTimeZone",
  FIRM_SEND_ADMINCONSENT_EMAIL: "api/firm/sendAdminConsentEmail",

  //Plugins
  GET_ALL_PLUGINS: "/api/plugin/Index",
  GET_FIRM_SETTINGS: "/api/firm/getFirmSetting",

  //IManage 
  CHECK_IMANAGE_REFRESH_TOKEN: "/api/imanage/checkRefreshToken",
  GET_IMANAGE_LIBRARIES: "/api/imanage/getLibraries",
  SEARCH_IMANAGE_WORKSPACE: "/api/imanage/searchWorkspace",
  ATTACH_IMANAGE_WITH_WORKSPACE: "/api/imanage/attachWithWorkspace",
  DEATTACH_IMANAGE_FROM_WORKSPACE: "/api/imanage/deattachFromWorkspace",
  GET_IMANAGE_ROUTE: "/api/imanage/getIManageRoute",
  GET_IMANAGE_ASSOCIATED_MATTERS: "/api/IManage/getAssociatedMatters",

};

export default apiUrls;
