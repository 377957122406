export const sidebarRoute = {
    APP_NOTIFICAITON_ROUTE: "/notification/app-notification",
    WHATS_NEW_ROUTE: "/notification/whats-new",
    ONBOARDING_ROUTE: "/notification/onboarding",
    POWERSHELL_ROUTE: "/notification/powershell",
    SUPPORT_ROUTE: "/notification/support",
    RULE_UPDATE_ROUTE: "/notification/rule-update",
    TRAINING_ROUTE: "/notification/training",
    RELEASE_NOTES_ROUTE: "/notification/release-notes",


    SIDEBAR_ITEMS: [
        { key: 32, value: "/notification/app-notification", name: "App Notification" },
        { key: 37, value: "/notification/whats-new", name: "What's New" },
        { key: 40, value: "/notification/onboarding", name: "Onboarding" },
        { key: 38, value: "/notification/support", name: "Support Manual" },
        { key: 30, value: "/notification/rule-update", name: "Rule Update" },
        { key: 43, value: "/notification/training", name: "Training" },
        { key: 34, value: "/notification/release-notes", name: "Release Notes" },
    ],
}